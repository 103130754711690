.container.jobs .wrap {
    width: auto;
}
.jobs-container {
    position: relative;
    text-align: center;
    padding-bottom: 300px;
}
.jobs-container .jobs-button {
    text-align: left;
    padding-top: 300px;
}
.jobs-container .btn-jobs {
    font-size: 24px;
    font-weight: 700; 
    color: #fff; 
    background-color: #d9d9d9;
    padding: 15px 45px;
    border-radius: 30px
}
.jobs-container .btn-jobs.active {
    background-color: #000;
}
.jobs-container .btn-jobs:last-child {
    margin-left: 30px;
}
/* main */
.jobs-section.main {
    padding-top: 200px;
}
.jobs-section.main .main-text,
.jobs-section.main .main-text .color {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
}
.jobs-section.main .main-text .color {
    color: #6c5ce7;
}
.jobs-section.main .main-info {
    color: #666;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
}
.jobs-section.main .main-img {
    padding-top: 150px;
}
/* main */

/* section common */
.jobs-section .pt-100 {
    padding-top: 100px;
}
.jobs-section .title {
    display: flex;
}
.jobs-section .title > div {
    text-align: left;
    padding-left: 45px;
}
.jobs-section .title .title-main {
    font-size: 40px;
    font-weight: 700;
}
.jobs-section .title .title-sub {
    color: #666;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
}
.jobs-section .jobs-img {
    padding-top: 40px;
}
/* section common */

/* section1 */
.jobs-section.section1 {
    text-align: left;
    padding-top: 100px;
}
/* section1 */

/* section2 */
.jobs-section.section2 {
    padding-top: 200px;
    text-align: left;
}
/* section2 */

/* 반응형 */
@media screen and (max-width: 767px) {
    .jobs-section.main {
        padding-top: 80px;
    }
    .jobs-section.main .main-title {
        color: #6c5ce7;
        font-size: 28px;
        font-weight: 700;
        line-height: 38px;
    }
    .jobs-section.main .main-text {
        padding-top: 40px;
    }
    .jobs-section.main .main-text, 
    .jobs-section.main .main-text .color {
        font-size: 24px;
        line-height: 33px;
    }
    .jobs-section.main .main-text .color {
        color: #000;
    }
    .jobs-section.main .main-info {
        padding-top: 20px;
        font-size: 16px;
        line-height: 22px;
    }
    .jobs-section.main .main-img {
        padding-top: 80px;
    }
    .jobs-container .text-line {
        padding: 20px 0;
    }
    .jobs-section .title .title-main {
        font-size: 24px;
    }
    .jobs-section .title .title-sub {
        font-size: 16px;
        line-height: 22px;
    }
    .jobs-section .jobs-img {
        padding-top: 30px;
    }
    .jobs-container .jobs-button {
        text-align: center;
        padding-top: 100px;
    }
    .jobs-container .jobs-button .btn-jobs {
        font-size: 16px;
        padding: 10px 28px;
    }
    .jobs-container .btn-jobs:last-child {
        margin-left: 4%;
    }
    /* section1 */
    .jobs-section.section1 {
        text-align: center;
    }
    .jobs-section .title {
        justify-content: center;
    }
    .jobs-section .title > div {
        text-align: center;
        padding: 0;
    }
    /* section1 */

    /* section2 */
    .jobs-section.section2 {
        text-align: center;
        padding-top: 100px;
    }
    /* section2 */

    /* 이미지 */
    .jobs-section .move-image .move-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }
    .jobs-section .move-image .nav-img {
        padding: 0 33px;
        font-size: 16px;
        font-weight: 400;
        color: #666;
    }
    .jobs-section .btn-prev {
        background-image: url("assets/images/mobile/icon_prev@2x.png");
        background-size: 32px;
        width: 32px;
        height: 32px;
    }
    .jobs-section .btn-next {
        background-image: url("assets/images/mobile/icon_next@2x.png");
        background-size: 32px;
        width: 32px;
        height: 32px;
    }
    /* 이미지 */
}
/* 반응형 */