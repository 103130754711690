.footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #f9fafb;
    padding: 30px 0px;
}
.footer .wrap {
    width: auto;
    flex-direction: column;
    align-items: flex-start;
}
.footer p {
    color: #666;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
}
.footer p.copyrights {
    color: #000;
    padding-top: 24px;
}

@media screen and (max-width: 768px) {
    .footer p {
        font-size: 10px;
    }
}