.blockchain .wrap {
    width: auto;
}
.blockchain-container {
    padding-bottom: 300px;
}
/* section-main */
.blockchain-section.main {
    padding-top: 200px;
    text-align: center;
}
.blockchain-section.main .main-title {
    padding-bottom: 63px;
    color: #6c5ce7;
    font-size: 40px;
    font-weight: 700;
}
.blockchain-section.main .main-text {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
}
.blockchain-section.main .bc-main-img {
    padding-top: 126px;
    width: 100%;
}
/* section-main */

/* section common */
.blockchain-section .title {
    display: flex;
}
.blockchain-section .title > div {
    padding-left: 45px;
}
.blockchain-section .title .title-main {
    font-size: 40px;
    font-weight: 700;
}
.blockchain-section .title .title-sub {
    color: #666;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
}
.blockchain-section .content {
    padding-top: 40px;
}
.blockchain-section {
    padding-top: 300px;
}
/* section common */

/* section1 */
.blockchain-section.section1 .content {
    display: flex;
}
.blockchain-section.section1 .content-img,
.blockchain-section.section1 .text-container {
    width: 50%;
}
.blockchain-section.section1 .content-img {
    object-fit: contain;
    margin-right: 30px;
}
.blockchain-section.section1 .text-container {
    margin-left: 30px;
}
.blockchain-section.section1 .text-box p {
    color: #666;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
}
.blockchain-section.section1 .text-box .title {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
}
.blockchain-section.section1 .text-box .number {
    color: #6c5ce7;
    font-size: 50px;
    font-weight: 400;
    line-height: 68px;
}
/* section1 */

/* section2 */
.blockchain-section.section2 .content-img {
    width: 100%;
}
/* section2 */

@media screen and (max-width: 767px) {
    .blockchain-container {
        text-align: center;
    }
    .blockchain-section {
        padding-top: 100px;
    }
    .blockchain-section.main {
        padding-top: 80px;
    }
    .blockchain-section.main .main-title {
        font-size: 28px;
        padding-bottom: 40px;
    }
    .blockchain-section.main .main-text {
        font-size: 24px;
        line-height: 33px;
    }
    .blockchain-container .text-line {
        padding: 20px 0;
    }
    .blockchain-section.main .bc-main-img {
        padding-top: 50px;
    }
    .blockchain-section .title {
        justify-content: center;
    }
    .blockchain-section .title .title-main {
        font-size: 24px;
    }
    .blockchain-section .title .title-sub {
        font-size: 16px;
        line-height: 22px;
    }
    .blockchain-section .title > div {
        padding: 0;
    }
    .blockchain-section .content {
        padding-top: 30px;
    }
    .blockchain-section.section1 .content-img {
        width: 100%;
        margin: 0;
    }
}