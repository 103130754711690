.nav-mobile {
    display: none;
}
/* 반응형 */
@media screen and (max-width: 768px) {
    .nav-mobile {
        display: block;
        height: 100vh;
        background-color: #fff;
    }
    .nav-mobile .nav-mobile-logo {
        display: flex;
        align-items: center;
    }
    .nav-mobile .nav-mobile-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0 2%;
        height: 60px;
    }
    .nav-mobile .nav-mobile-list {
        padding: 0 24px 20px;
    }
    .nav-mobile .nav-mobile-item {
        position: relative;
        text-align: left;
    }
    .nav-mobile .nav-mobile-link {
        width: 100%;
        padding: 25px 0;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #000;
        border-bottom: 1px solid #e6e6e6;
    }
}
/* 반응형 */