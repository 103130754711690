.notfound-container {
    height: 100vh;
}
.notfound-container .notfound-wrap {
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.notfound-container .btn-main {
    width: 200px;
    height: 60px;
    border: 1px solid #6c5ce7;
    border-radius: 32px;
    color: #6c5ce7;
    font-size: 24px;
    font-weight: 700;
    margin-top: 50px;
}
@media screen and (max-width: 767px) {
    .notfound-container .error-img {
        max-width: 400px;
        width: 100%;
    }
}
