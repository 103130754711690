.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    height: var(--header-height-);
    background: transparent;
    -webkit-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
}
.header.detail {
    background-color: #fff;
}
.inner {
    padding: 0 2%;
    position: relative;
    display: flex;
}
.header .inner > button.logo {
    display: flex;
    align-items: center;
    width: auto;
    height: var(--header-height-);
    line-height: var(--header-height-);
    transition: all 0.3s ease;
}
.header .nav {
    width: 100%;
    text-align: center;
}
.header .header-menu {
    display: none;
}
.header .nav .nav-depth {
    display: flex;
    align-items: center;
    padding-left: 5%;
}
.header .nav .nav-depth li {
    position: relative;
    display: inline-block;
    padding: 0 30px;
    transition: all 0.3s ease;
}
.header .nav li > button.nav-link,
.header .nav li > a.nav-link {
    display: block;
    line-height: var(--header-height-);
    font-size: 19px;
    font-weight: 400;
    color: #fff;
    transition: all 0.1s ease;
}
.header.dark .nav li > button,
.header.dark .nav li > a {
    color: #000;
}

/* 상세페이지 nav */
.company .nav-detail {
    display: none;
}
.company:hover .nav-detail {
    display: block;
    position: absolute;
    left: -5%;
    padding: 15px 0;
    width: 150px;
    background-color: #fff;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
    border-radius: 20px;
    text-align: left;
    z-index: inherit;
}
.nav-detail .nav-item {
    padding: 9px 19px;
}
.nav-detail .nav-item > a {
    color: #000;
    font-size: 16px;
    font-weight: 700;
}

/* 반응형 */
@media screen and (max-width: 768px) {
    .header {
        height: 60px;
    }
    .inner {
        justify-content: space-between;
    }
    .header .inner > button.logo {
        height: 60px;
        line-height: 60px;
    }
    .header .nav {
        position: fixed;
        top: 0;
        right: -100%;
        width: 100%;
        height: 100%;
        margin-left: 0;
        z-index: 10;
        -webkit-transition: right .5s ease;
        transition: right .5s ease;
    }
    .header .nav .nav-depth {
        display: none;
    }
    .header .header-menu {
        display: block;
    }
}

/* 반응형 */

/* 상세페이지 nav */

/* .header .nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
}
.header .nav .nav-depth {
    display: flex;
    justify-content: center;
    padding: 0 0 0 11%;
}
.header .nav .nav-depth li {
    position: relative;
    display: inline-block;
    padding: 0 30px;
    transition: all 0.3s ease;
}
.header .inner a {
    display: flex;
    align-items: center;
    width: auto;
    height: var(--header-height-);
    line-height: var(--header-height-);
    transition: all 0.3s ease;
}
.header .nav li > button,
.header .nav li > a {
    display: block;
    line-height: var(--header-height-);
    font-size: 19px;
    font-weight: 400;
    color: #fff;
    transition: all 0.7s ease;
} */