.container {
    position: relative;
    padding-top: 80px;
}
.section-common {
    position: relative;
    width : 100%;
    height : calc(100% - 80px);
    display: flex;
    justify-content : center;
    align-items: center;
    font-size: 36px;
}
.section-common * {
    color: #fff;
    font-size: 40px;
}
.wrap {
    position: relative;
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 25px;
}
.section-common .background {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}
/* section 1 */
.section-area1 {
    position: absolute;
    top: 0;
}
.section-area1 .image-number {
    font-weight: 700;
}
.section-area1 .image-number .total-number {
    font-size: 24px;
    font-weight: 300;
}
.section-area1 .image-button {
    padding-top: 10px;
}
.section-area1 .title-text {
    font-size: 90px;
    font-weight: 700;
}
.section-area1 .text {
    padding-top: 10px;
    font-size: 40px;
    font-weight: 400;
}
.section-area1 .detail-button {
    padding-top: 60px;
}
.section-area1 .detail-button > a {
    padding: 12px 51px;
    color: #6c5ce7;
    background-color: #fff;
    font-size: 20px;
    font-weight: 700;
    border-radius: 60px;
}
/* section 1 */

/* youtube */
.section-area1 .youtube-container {
    position: absolute;
    top: 80px;
    right: 0px;
    height: 400px;
    display: flex;
    -webkit-transition: right .5s ease;
    transition: right .5s ease;
}
.section-area1 .youtube-box {
    padding: 20px;
    background: rgba(0, 0, 0, 0.5);
    
}
.btn-youtube[aria-expanded=true]+.youtube-container {
    right: 0;
}
/* youtube */

/* section3 */
.section-area3 .wrap {
    width: auto;
    display: flex;
    align-items: center;
}
.section-area3 .media {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}
.section-area3 .media .media-item {
    position: relative;
    padding: 10px;
    cursor: pointer;
}
.section-area3 .media .media-item.no-data {
    cursor: default;
}
.section-area3 .media .media-item .line {
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    overflow: hidden;
}
.section-area3 .media .media-item .media-img {
    width: 100%;
}
.section-area3 .media .media-item .title {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    padding-bottom: 25px;
}
.section-area3 .media .media-item .date {
    position: absolute;
    bottom: 0;
    color: #9e9e9e;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
/* section3 */

/* section 4 */
.section-area4 > .wrap {
    padding-bottom: 160px;
}
.section-area4 .contact {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.section-area4 .contact .contact-item {
    width: 50%;
}
.section-area4 .contact .contact-item:first-child {
    max-height: 640px;
    margin-right: 20px;
}
.section-area4 .contact .contact-item.map {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 20px;
}
.section-area4 .contact .contact-item .naver-map {
    min-height: 500px; 
    z-index: 0; 
    border-radius: 25px;
}
.section-area4 .contact .line {
    border: 1px solid #000;
    border-radius: 25px;
}
.section-area4 .contact .line.address {
    margin-top: 10px;
    padding: 28px 45px;
}
.section-area4 .contact .line > img {
    width: 100%;
    height: 100%;
}
.section-area4 .contact .line.address p,
.section-area4 .contact .line.address span {
    color: #000;
    font-size: 16px;
    font-weight: 700;
}
.section-area4 .contact .line.address span.address-text {
    padding-left: 16px;
    font-size: 14px;
    font-weight: 500;
}
/* section 4 */

.scroll-img {
    position: absolute;
    bottom: 0;
    right: 100px;
}
.slide-navigation button:nth-child(1) {
    display: none;
}
.slide-navigation button:nth-last-child(1) {
    display: none;
}
.slide-navigation {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 1111;
}
.slide-navigation button:disabled {
    font-weight: 700;
}

/* 반응형 */
@media screen and (max-width: 768px) {
    .container {
        padding-top: 60px;
    }
    .section-common {
        height: calc(100% - 60px);
    }
    .section-common * {
        font-size: 24px;
    }
    .wrap {
        height: 100%;
        padding: 0 24px;
    }
    /* common */
    .scroll-img {
        right: 25px;
    }
    .header-menu[aria-expanded=true]+.nav {
        right: 0;
    }
    /* common */

    /* section1 */
    .section-area1 .main {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .section-area1 .image-number .total-number {
        font-size: 16px;
    }
    .section-area1 .title-text {
        font-size: 40px;
    }
    .section-area1 .text {
        font-size: 24px;
    }
    .section-area1 .detail-button {
        padding-top: 50px;
    }
    .section-area1 .detail-button > a {
        padding: 12px 35px;
        font-size: 16px;
    }
    /* section1 */

    /* section2 */
    .section-area2 .wrap {
        display: flex;
        align-items: center;
    }
    .section-area2 .about {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
    }
    .section-area2 .about-item {
        max-width: 100%;
    }
    /* section2 */

    /* section3 */
    .section-area3 .media {
        grid-template-columns: repeat(3, 1fr);
    }
    .section-area3 .media .media-item .title {
        padding: 0;
        font-size: 12px;
    }
    .section-area3 .media-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-area3 .media-button button {
        width: 130px;
        height: 32px;
        font-size: 16px;
        font-weight: 700;
        color: #6c5ce7;
        border: 1px solid #6c5ce7;
        border-radius: 30px;
    }
    /* section3 */

    /* section4 */
    .section-area4 .wrap {
        height: calc(100% - 210px);
    }
    .section-area4 .contact {
        height: 100%;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .section-area4 .contact .contact-item {
        width: 100%;
    }
    .section-area4 .contact .contact-item:first-child {
        margin: 10px 0;
        overflow: hidden;
    }
    .section-area4 .contact .contact-item.map {
        margin: 10px 0;
    }
    .section-area4 .contact .line.address {
        margin-top: 5px;
        padding: 10px 18px;
    }
    .section-area4 .contact .line.address span.address-text {
        font-size: 10px;
        padding: 0;
    }
    .section-area4 .contact .contact-item .naver-map {
        min-height: 100px;
        height: 100%;
    }
    .section-area4 .contact .line > img {
        border-radius: 25px;
    }
    /* section4 */
}
@media screen and (max-width: 600px) {
    .section-area3 .media {
        grid-template-columns: repeat(2, 1fr);
    }
    .section-area4 .contact .contact-item .map {
        display: none;
    }
    .section-area2 .scroll-img,
    .section-area3 .scroll-img {
        display: none;
    }
}
/* 반응형 */