/* default */
* {
  -webkit-text-size-adjust: none;
  font-size: 14px;
  color: #000;
  font-weight: 400;
  word-break: keep-all;
}
html,
body,
p,
input,
select,
form,
label,
mark,
ul,
ul li,
ol,
ol li,
dl,
dl dt,
dl dd,
img,
a,
table,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}
img {
  max-width: 100%;
  border: 0;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
ul,
ol {
  list-style: none;
}
a {
  color: #555;
  text-decoration: none;
}
a:hover,
a:active,
a:visited {
  text-decoration: underline;
}
table {
  border: 0;
  border-collapse: collapse;
  table-layout: fixed;
}

.hidden {
  position: absolute;
  left: -20000px;
}
/**/
::selection {
  background: #833f9e;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background: #833f9e;
  color: #fff;
  text-shadow: none;
}
::-webkit-selection {
  background: #833f9e;
  color: #fff;
  text-shadow: none;
}

a {
  opacity: 1;
  display: inline-block;
  text-decoration: none !important;
}

input,
textarea {
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  padding: 0;
}
input::-webkit-input-placeholder {
  color: #505358;
}
textarea::-webkit-input-placeholder {
  color: #505358;
}

.inp:focus {
  border-color: #5cc776 !important;
}
.select:focus {
  border-color: #5cc776 !important;
}
.textarea:focus {
  border-color: #5cc776 !important;
}
body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  word-wrap: break-word;
  word-break: keep-all;
}
body::-webkit-scrollbar {
  display: none;
}
.is-scroll-lock {
  overflow: hidden;
  height: 100%;
}
html,
body {
  height: 100%;
}
#root {
  height: 100%;
}
:root {
  --header-height-: 80px;
  --header-open-height-: 200px;
  --header-scroll-height-: 72px;
  --toggle-height-: 34px;
}
/* default */

/* loading */
.global-loading-background {
  position: absolute;
  width: 100%;
  height: calc(100% - 160px);
  background-color: #fff;
  z-index: 999;
}
.global-loading-background .icon-area {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MuiCircularProgress-circleIndeterminate {
  color: #6c5ce7;
}
@media screen and (max-width: 767px) {
  .global-loading-background {
    height: calc(100% - 120px);
  }
}
/* loading */
