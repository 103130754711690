.metaverse .wrap {
    width: auto;
}
.metaverse-container {
    padding-bottom: 300px;
}
.metaverse-container img {
    width: 100%;
}
.metaverse-container .torymeta-logo {
    width: auto;
}
.metaverse-container .text-line {
    width: auto;
}
.metaverse-wrap {
    display: flex;
    align-items: center;
    padding-top: 100px;
}
.metaverse-wrap .content {
    width: 50%;
}
.metaverse-wrap .content:first-child {
    margin-right: 20px;
}
.metaverse-wrap .content:last-child {
    margin-left: 20px;
}
.metaverse-wrap .content .text-main {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
}
.metaverse-wrap .content .text-info {
    color: #666;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
}
.metaverse-wrap .content .content-qr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
}
.metaverse-wrap .qr-box p {
    color: #666;
    font-size: 12px;
    font-weight: 700;
}
.metaverse-wrap .qr-box .line {
    padding: 10px;
    margin-top: 5px;
    border: 2px solid #d9d9d9;
    border-radius: 15px;
}
.metaverse-wrap .qr-box img {
    width: 100%;
}
.metaverse-wrap .button-box button {
    width: 160px;
    height: 56px;
    color: #fff;
    background: #6c5ce7;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
}
.metaverse-wrap .pt-50 {
    padding-top: 50px;
}
.metaverse-wrap .pt-40 {
    padding-top: 40px;
}
.metaverse-wrap .pt-30 {
    padding-top: 30px;
}
.metaverse-wrap .pt-20 {
    padding-top: 20px;
}

/* section2 */
.metaverse-section {
    padding-top: 300px;
}
.metaverse-section .title {
    display: flex;
}
.metaverse-section .title > div {
    padding-left: 45px;
}
.metaverse-section .title .title-main {
    font-size: 40px;
    font-weight: 700;
}
.metaverse-section .title .title-sub {
    color: #666;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
}
.metaverse-section .content {
    padding-top: 40px;
}
/* section2 */

/* section3 */
.section3 .metaverse-img4 {
    max-width: 800px;
    padding-bottom: 40px;
}
/* section 3 */

@media screen and (max-width: 767px) {
    .metaverse-container {
        text-align: center;
    }
    .metaverse-wrap {
        flex-direction: column;
        padding-top: 80px;
    }
    .metaverse-wrap .content {
        width: 100%;
    }
    .metaverse-wrap .content:first-child {
        margin: 0;
    }
    .metaverse-wrap .content .text-main {
        font-size: 24px;
        line-height: 33px;
    }
    .metaverse-wrap .content .text-info {
        font-size: 16px;
        line-height: 22px;
    }
    .metaverse-container img {
        width: auto;
        max-width: 100%;
    }
    .metaverse-wrap .content .metaverse-img {
        padding-top: 70px;
    }
    .metaverse-wrap .content .button-box.column {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .metaverse-wrap .content .button-box.column button {
        margin-top: 60px;
    }
    .metaverse-section .title {
        justify-content: center;
    }
    .metaverse-section .title > div {
        padding: 0;
    }
    .metaverse-section .title .title-main {
        font-size: 24px;
    }
    .metaverse-section .title .title-sub {
        font-size: 16px;
        line-height: 22px;
    }
    .metaverse-container .text-line {
        padding: 20px 0;
    }
    .metaverse-section {
        padding-top: 100px;
    }
    /* section3 */
    .metaverse-section.section3 .metaverse-img4 {
        padding: 0;
    }
    .metaverse-section.section3 .img-text {
        padding-top: 10px;
        color: #9e9e9e;
        font-size: 12px;
        font-weight: 400;
    }
    .metaverse-section.section3 .move-image {
        padding-top: 60px;
    }
    .metaverse-section.section3 .move-image .move-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }
    .metaverse-section.section3 .move-image .nav-img {
        padding: 0 33px;
        font-size: 16px;
        font-weight: 400;
        color: #666;
    }
    .metaverse-section.section3 .btn-prev {
        background-image: url("assets/images/mobile/icon_prev@2x.png");
        background-size: 32px;
        width: 32px;
        height: 32px;
    }
    .metaverse-section.section3 .btn-next {
        background-image: url("assets/images/mobile/icon_next@2x.png");
        background-size: 32px;
        width: 32px;
        height: 32px;
    }
    /* section3 */
}