.nft .wrap {
    width: auto;
}
.nft-container {
    padding-bottom: 300px;
}
/* section-main */
.nft-section.main {
    padding-top: 200px;
    text-align: center;
}
.nft-section.main .main-title {
    padding-bottom: 63px;
    color: #6c5ce7;
    font-size: 40px;
    font-weight: 700;
}
.nft-section.main .main-text {
    padding: 63px 0;
}
.nft-section.main .main-text * {
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
}
.nft-section.main .bc-main-img {
    padding-top: 126px;
    width: 100%;
}
.nft-section.main .main-button {
    width: 160px;
    height: 56px;
    color: #fff;
    background: #6c5ce7;
    border-radius: 30px;
    font-size: 20px;
    font-weight: 700;
}
/* section-main */

/* secion-common */
.nft-section {
    padding-top: 300px;
}
.nft-section .text-container p {
    color: #666;
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
}
.nft-section .text-container .title{
    color: #6c5ce7;
    font-size: 40px;
    font-weight: 700;
    line-height: 54px;
}
.nft-section .text-container .info-text {
    padding: 50px 0 30px;
}
.nft-section .text-container .info {
    color: #000;
    font-size: 50px;
    font-weight: 700;
    line-height: 68px;
}
/* secion-common */

/* section1 */
.nft-section.section1 {
    display: flex;
}
.nft-section.section1 .content-img,
.nft-section.section1 .text-container {
    width: 50%;
}
.nft-section.section1 .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 40px;
}
.nft-section.section1 .content-img {
    object-fit: contain;
    margin-left: 40px;
}
/* section1 */

/* section2 */
.nft-section.section2 {
    display: flex;
}
.nft-section.section2 .content-img,
.nft-section.section2 .text-container {
    width: 50%;
}
.nft-section.section2 .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 40px;
}
.nft-section.section2 .content-img {
    object-fit: contain;
    margin-right: 40px;
}
.nft-section.section2 * {
    text-align: right;
}
/* section2 */

/* 반응형 */
@media screen and (max-width: 767px) {
    .nft-container {
        text-align: center;
    }
    .nft-section.main {
        padding-top: 80px;
    }
    .nft-section {
        padding-top: 100px;
    }
    .nft-section.main .main-text {
        padding: 40px 0 60px;
    }
    .nft-section.main .main-text * {
        font-size: 24px;
        line-height: 33px;
    }
    .nft-section.main .main-button {
        width: 150px;
        height: 44px;
        font-size: 16px;
    }
    .nft-section.section1 .content-img,
    .nft-section.section2 .content-img {
        width: 100%;
        margin: 0;
    }
}
/* 반응형 */